/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import App from 'next/app';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { SessionProvider } from 'next-auth/react';
import * as Sentry from '@sentry/react';
import 'react-multi-carousel/lib/styles.css';
import 'draft-js/dist/Draft.css';
import './landing-page/landing-page.css'


import { DefaultSeo, app } from 'next-seo';
import NProgress from 'nprogress';
import { Router } from 'next/router';
import CssBaseline from '@mui/material/CssBaseline';
import { initializeApollo, useApollo } from 'config/apollo/apollo-client';

import { getDataFromTree } from '@apollo/react-ssr';
import { StateProvider } from '@app/utils/state';
import GlobalStyle, { theme as styledTheme } from '../config/globalStyles';
import materialTheme from '../config/theme';
import { appWithTranslation } from '../config/i18n';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: `mubazar@${process.env.npm_package_version}`,
  enabled: process.env.NEXT_PUBLIC_NODE_ENV === 'production',
  environment: process.env.NEXT_PUBLIC_APP_ENV,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // autoSessionTracking: true
});

const DEFAULT_SEO = {
  title: 'Scivenia',
  description: 'Find scientific opportunities near you, Events for scientists. All in one place.',
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://www.scivenia.com',
    title: 'Scivenia',
    description: 'Find scientific opportunities near you, Events for scientists. All in one place.',
    images: [
      {
        url: 'https://www.scivenia.com/images/LogoAfterLogin.png',
        width: '400',
        height: 400,
      },
    ],
    site_name: 'SciVenia',
    // imageWidth: 1200,
    // imageHeight: 1200,
  },
  twitter: {
    // handle: '@garmeeh',
    cardType: 'summary_large_image',
  },
};

function MyApp({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const handleRouteChange = (url) => {
      NProgress.start();
    };
    const handleRouteEnd = (url) => {
      NProgress.done();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    Router.events.on('routeChangeStart', handleRouteChange);
    Router.events.on('routeChangeComplete', handleRouteEnd);
    Router.events.on('routeChangeError', handleRouteEnd);

    return () => {
      Router.events.off('routeChangeStart', () => 1);
      Router.events.off('routeChangeComplete', () => 1);
      Router.events.off('routeChangeError', () => 1);
    };
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <StyledThemeProvider theme={styledTheme}>
        <GlobalStyle />
        <ThemeProvider theme={materialTheme}>
          <StateProvider>
            <CssBaseline />
            <GlobalStyle />
            <DefaultSeo {...DEFAULT_SEO} />
            <Component {...pageProps} err={err} />
          </StateProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
  if (Object.keys(pageProps).length > 0) {
    return { pageProps };
  }
  return { pageProps };
};

export default appWithTranslation(MyApp);
