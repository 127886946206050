import { gql } from '@apollo/client';
import Fragments from '../fragments';
// import Fragemets from '../fragments';

export const GET_EVENT = gql`
  query($slug: String!) {
    opportunity(slug: $slug) {
      ...opportunityCard
    }
  }
  ${Fragments.opportunityCard}
`;

export const SUBMISSION_APPLICATION_FORM = gql`
  query($slug: String!) {
    submissionApplicationForm(slug: $slug) {
      id
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        child_fields {
          id
          label
          type
          slug
        }
        choices
      }
      terms
      supported_languages
    }
  }
`

export const REGISTRATION_APPLICATION_FORM = gql`
  query ($slug: String!) {
    registrationApplicationForm(slug: $slug) {
      id
      
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        child_fields {
          id
          label
          type
          slug
        }
        choices
      }
      opportunity {
        ...opportunityCard
      }
      terms
      supported_languages
    }
  }
  ${Fragments.opportunityCard}

 
`;

export const REVIEW_APPLICATION_FORM = gql`
  query($slug: String!) {
    reviewApplicationForm(slug: $slug) {
      id
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        child_fields {
          id
          label
          type
          slug
        }
        grades {
          id
          grade
          label
        }
        choices
      }
      supported_languages
    }
  }
`

export const APPLICATION_FORM = gql`
  query($opportunity: String!, $parent_id: String) {
    applicationForm(slug: $opportunity) {
      id
      opportunity {
        ...opportunityMini
      }
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        ... on SelectField {
          choices
        }
        ... on GroupField {
          child_fields {
            id
            label
            type
            slug
            subtype
            is_required
            ... on SelectField {
              choices
            }
          }
        }
      }
      supported_languages
      is_skipped

      terms
      is_active
      max_members
    }

    instruments(parent_id: $parent_id, orderBy: {field: SLUG, order: ASC }) {
      id
      name
      slug
      created_at
      parent {
        id
        name
      }
      children {
        id
        name
      }
    }
    genres(orderBy: {field: SLUG, order: ASC }) {
      id
      name
      slug
    }
    event_types {
      id
      name
    }
  }
  ${Fragments.opportunityMini}
`;

export const TRACK_APPLICATION_FORM = gql`
  query($track_id: ID!) {
    trackApplicationForm(track_id: $track_id) {
      id
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        child_fields {
          id
          label
          type
          slug
        }
        choices
      }
      supported_languages
    }
  }
`;

export const OPPORTUNITY_FORM = gql`
  query opportunityForm($opportunity: ID!) {
    opportunityForm(opportunity: $opportunity) {
      id
      opportunity {
        ...opportunityMini
      }
      fields {
        id
        # label
        type
        order
        slug
        is_required
        ... on BuilderField {
          options
          choices
          label
          child_fields {
            id
            slug
          }
        }
      }
      terms
      supported_languages
      is_active
      is_skipped
      max_members
    }
  }

  ${Fragments.opportunityMini}
`;

export const OPPORTUNITY_REGISTRATION_FORM = gql`
  query opportunityRegistrationForm($opportunity: ID!) {
    opportunityRegistrationForm(opportunity: $opportunity) {
      id
      fields {
        id
        label
        type
        order
        subtype
        is_required
        choices
        child_fields {
					id
          label
        	slug
          type
        }
        grades {
          id
          grade
          label
        }
      }
      terms
      supported_languages
      is_active
      is_skipped
      max_members
    }
  }
`;

export const OPPORTUNITY_SUBMISSION_FORM = gql`
  query opportunitySubmissionForm($opportunity: ID!) {
    opportunitySubmissionForm(opportunity: $opportunity) {
      id
      fields {
        id
        label
        type
        order
        subtype
        is_required
        choices
        child_fields {
					id
          label
        	slug
          type
        }
        grades {
          id
          grade
          label
        }
      }
      terms
      submission_instructions
      supported_languages
      is_active
      is_skipped
      max_members
      original_terms
    }
  }
`;

export const TRACK_SUBMISSION_FORM = gql`
  query trackSubmissionForm($track: ID!) {
    trackSubmissionForm(track: $track) {
      id
      fields {
        id
        label
        type
        order
        subtype
        is_required
      }
      terms
      supported_languages
      is_active
      is_skipped
      max_members
    }
  }
`;

export const OPPORTUNITY_REVIEW_FORM = gql`
  query opportunityReviewForm($opportunity: ID!) {
    opportunityReviewForm(opportunity: $opportunity) {
      id
      fields {
        id
        label
        type
        order
        subtype
        is_required
        choices
        grades {
          id
          grade
          label
        }
        child_fields {
          id
          label
          slug
          type
        }
      }
      terms
      supported_languages
      is_active
      is_skipped
      max_members
    }
  }
`;

export const OPPORTUNITY_BRIEF = gql`
  query($slug: String!) {
    opportunity(slug: $slug) {
      ...opportunityCard
      ...opportunityReviewer
      ...opportunityPayment
    }
  }
  ${Fragments.opportunityCard}
  ${Fragments.opportunityReviewer}
  ${Fragments.opportunityPayment}
`;

export const OPPORTUNITY_APPLICATIONS = gql`
  query($id: ID!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    opportunityApplications(id: $id, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        payment_status
        rate
        user_rate
        created_at
        email
        avatar
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        name
        artist_name
      }
    }
  }
  ${Fragments.paginator}
`;

export const OPPORTUNITY_SUBMISSION_APPLICATIONS = gql`
  query opportunitySubmissionApplications($slug: String!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    opportunitySubmissionApplications(slug: $slug, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        payment_status
        created_at
        code
        opportunity {
          id
          slug
        }
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        reviewers {
          id
          name
          email
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_name
            type
            value
          }
        }
      }
    }
  }
  ${Fragments.paginator}
`;

export const REVIEWER_SUBMISSION_APPLICATIONS = gql`
  query reviewerSubmissionApplications($slug: String!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    reviewerSubmissionApplications(slug: $slug, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        application_review_id
        status
        payment_status
        created_at
        code
        opportunity {
          id
          slug
        }
        track {
          id
          slug
        }
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        reviewStatus
        reviewed
        invitationStatus
        reviewers {
          id
          name
          email
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_name
            type
            value
          }
        }
      }
    }
  }
  ${Fragments.paginator}
`;

export const OPPORTUNITY_REVIEW_APPLICATIONS = gql`
  query opportunityReviewApplications($slug: String!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    opportunityReviewApplications(slug: $slug, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        submission_application_id
        status
        code
        opportunity {
          id
          slug
        }
        reviewers {
          id
          application_review_id
          name
          email
          review_status
          is_scientist
          is_verified
          has_reviews
          elements {
            field_id
            field_name
            type
            subtype
            order
            value
            child_elements {
              field_id
              field_name
              type
              subtype
              order
              value
            }
          }
        }
        reviewed
        reviews
        application_elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_id
            field_name
            type
            subtype
            order
            value
          }
        }
      }
    }
  }
  ${Fragments.paginator}
`;

export const OPPORTUNITY_REGISTRATION_APPLICATIONS = gql`
  query opportunityRegistrationApplications($slug: String!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    opportunityRegistrationApplications(slug: $slug, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
      id
      status
      payment_status
      code
      opportunity {
        id
        slug
        approval_type
      }
      elements {
        field_id
        field_name
        type
        subtype
        order
        value
        child_elements {
          field_id
          field_name
          type
          subtype
          order
          value
        }
      }
    }
    }
  }
  ${Fragments.paginator}
`;

export const OPPORTUNITY_FEEDBACK_APPLICATIONS = gql`
  query opportunityApplicantFeedback($slug: String!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    opportunityApplicantFeedback(slug: $slug, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        opportunity {
          id
          slug
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_id
            field_name
            type
            subtype
            order
            value
          }
        }
      }
    }
  }
  ${Fragments.paginator}
`;


export const USER_APPLICATIONS = gql`
  query($first: Int!, $page: Int) {
    userApplications(first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        rate
        user_rate
        created_at
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        opportunity {
          id
          title
          slug
          organisation {
            id
            slug
            name
            email
            logo {
              name
              path
              thumb
            }
          }
          logo {
            name
            thumb
            path
          }
          genres {
            name
          }
          deadline_date
          start_date
        }
        created_at
      }
    }
  }
  ${Fragments.paginator}
`;

export const GET_USER_APPLICATIONS = gql`
  query($filters: ApplicationFilterInput, $first: Int!, $page: Int) {
    userApplications(filters: $filters, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        payment_status
        rate
        user_rate
        created_at
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        opportunity {
          id
          title
          slug
          organisation {
            slug
            name
          }
          logo {
            name
            thumb
            path
          }
          deadline_date
          start_date
          is_virtual
        }
        created_at
      }
    }
  }
  ${Fragments.paginator}
`;

export const GET_USER_REGISTRATION_APPLICATIONS = gql`
  query($slug: String, $filters: ApplicationFilterInput, $first: Int!, $page: Int) {
    userApplicationRegistrations(slug: $slug, filters: $filters, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        payment_status
        rate
        user_rate
        created_at
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        opportunity {
          id
          title
          approval_type
          slug
          organisation {
            slug
            name
          }
          logo {
            name
            thumb
            path
          }
          deadline_date
          start_date
          end_date
        }
        created_at
      }
    }
  }
  ${Fragments.paginator}
`;

export const USER_SUBMISSION_APPLICATIONS = gql`
  query userSubmissionApplications($slug: String!, $first: Int!, $filters: ApplicationFilterInput, $page: Int) {
    userSubmissionApplications(slug: $slug, first: $first, filters: $filters, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        status
        code
        opportunity {
          id
          slug
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_id
            field_name
            type
            subtype
            order
            value
          }
        }
      }
    }
  }
  ${Fragments.paginator}
`;

export const APPLICATION_OPPORTUNITY_QUERY = gql`
  query($id: ID!) {
    applicationOpportunity(id: $id) {
      title
      slug
      organisation {
        slug
        name
        logo {
          name
          path
          thumb
        }
        account {
          account_id
        }
      }
      logo {
        name
        thumb
        path
      }
      genres {
        name
      }
      payment_details {
        title
        cost
        code
        currency
        slots
        description
      }
      deadline_date
      start_date
    }
  }
`;

export const APPLICATION_DETAILS = gql`
  query($id: ID!) {
    viewApplication(id: $id) {
      id
      status
      code
      #     opportunity
      user {
        id
        name
        email
        avatar {
          name
          thumb
          placeholder
        }
      }
      opportunity {
        id
        title
        event_type {
          id
          name
        }
        slug
        organisation {
          id
          slug
          name
          email
          logo {
            name
            path
            thumb
          }
        }
        logo {
          name
          thumb
          path
        }
        genres {
          name
        }
        event_types {
          id
          name
        }
        payment_details {
          title
          cost
          code
          currency
          slots
          description
        }
        deadline_date
        start_date
      }
      name
      artist_name
      email
      address
      elements {
        # id
        field_name
        type
        subtype
        ... on ApplicationScalarElement {
          value
        }
        ... on ApplicationFilesElement {
          files {
            name
            path
            thumb
          }
        }
        ... on ApplicationGroupElement {
          child_elements {
            field_name
            type
            value
          }
        }
      }
      rate
      user_rate
      created_at
      payment_status
      refunded_at

      ...applicationPayment
    }
  }
  ${Fragments.applicationPayment}
`;

export const SEARCH_OPPORTUNITY = gql`
  query($input: OpportunityFilterInput, $first: Int!, $page: Int) {
    opportunitySearch(input: $input, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        ...opportunityCard
      }
    }
  }

  ${Fragments.opportunityCard}
  ${Fragments.paginator}
`;

export const VIEW_OPPORTUNITY = gql`
  query($slug: String!) {
    opportunity(slug: $slug) {
     ...opportunityDetails
    }
  }
  ${Fragments.opportunityDetails}
`;

// export const OPPORTUNITY_DETAILS_PAGE = gql`
//   query($slug: String!, $input: OpportunityFilterInput) {
//     opportunity(slug: $slug) {
//       ...opportunityDetails
//     }
//     opportunitySearch(input: $input, first: 4) {
//       paginatorInfo {
//         ...paginator
//       }
//       data {
//         ...opportunityCard
//       }
//     }
//   }
//   ${Fragments.opportunityDetails}
//   ${Fragments.opportunityCard}
//   ${Fragments.paginator}
// `;

export const OPPORTUNITY_COUPONS = gql`
  query opportunityCoupons($opportunity: String!) {
    opportunityCoupons(opportunity: $opportunity) {
      ...couponMini
      #     created_by
    }
    opportunity(slug: $opportunity) {
      id
      title
      slug
      description
      user {
        id
      }
      organisation {
        id
        slug
        name
        email
        logo {
          name
          path
          placeholder
          thumb
        }
        city {
          id
          name
          country {
            name
          }
        }
      }
      ...opportunityPayment
    }
  }
  ${Fragments.couponMini}
  ${Fragments.opportunityPayment}
`;

export const GET_COUPON = gql`
  query getCoupon($code: String!, $opportunity: String!) {
    getCoupon(code: $code, opportunity: $opportunity) {
      ...couponMini
      # opportunity
      # created_by
    }
  }
  ${Fragments.couponMini}
`;

export const OPPORTUNITY_REVENUE = gql`
  query opportunityRevenue($opportunitySlug: String!) {
    opportunityRevenue(opportunitySlug: $opportunitySlug) {
      total_applications
      collected_revenue {
        amount
        currency
      }
      available_revenue {
        amount
        currency
      }
      refunded_revenue {
        amount
        currency
      }
    }
  }
`;

export const OPPORTUNITY_TRACKS = gql`
query($opportunity_id: String!) {
  opportunityTracks(opportunity_id: $opportunity_id) {
    id
    track_id
    track_name
    track_description
    track_admin
    slug
    track_start_date
    track_end_date
    track_keywords
    track_important_dates {
      date
      title
    }
    track_domain_areas {
      id
      name
      slug
      domain {
        id
        name
        slug
        created_at
      }
      created_at
    }
    submission_form {
        id
        fields {
          id
          label
          type
          order
          slug
          subtype
          is_required
          choices
          child_fields {
            id
            label
            slug
            type
          }
          grades {
            id
            grade
            label
          }
        }
        terms
        submission_instructions
        supported_languages
        is_active
        is_skipped
        max_members
      }
      review_form {
        id
        fields {
          id
          label
          type
          order
          slug
          subtype
          is_required
          choices
          child_fields {
            id
            label
            slug
            type
          }
          grades {
            id
            grade
            label
          }
        }
        supported_languages
        is_active
        is_skipped
        max_members
      }
  }
}
`


export const TRACKS_SUBMITION_FORM = gql`
  query ($track: ID!) {
    trackSubmissionForm(track: $track) {
      id
      opportunity {
        ...opportunityMini
      }
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        # ... on SelectField {
        #   choices
        # }
        # ... on GroupField {
        #   child_fields {
        #     id
        #     label
        #     type
        #     slug
        #     subtype
        #     is_required
        #     ... on SelectField {
        #       choices
        #     }
        #   }
        # }  
      }
      supported_languages
      is_skipped

      terms
      is_active
      max_members
    }
  }
  ${Fragments.opportunityMini}
`;

export const TRACK_REVIEW_FORM = gql`
  query ($track: ID!) {
    trackReviewForm(track: $track) {
      id
      opportunity {
        ...opportunityMini
      }
      track {
        id
      }
      fields {
        id
        label
        type
        order
        slug
        subtype
        is_required
        grades {
          id
          grade
          label
        }
        # ... on SelectField {
        #   choices
        # }
        # ... on GroupField {
        #   child_fields {
        #     id
        #     label
        #     type
        #     slug
        #     subtype
        #     is_required
        #     ... on SelectField {
        #       choices
        #     }
        #   }
        # }
      }
      supported_languages
      is_skipped

      terms
      is_active
      max_members
    }
  }
  ${Fragments.opportunityMini}
`;


export const OPPORTUNITY_MEMBER_ROLE = gql`
  query($id: ID!) {
    opportunityMemberRole(id: $id)
  }
`;

export const TRACK_MEMBER_ROLE = gql`
  query($id: ID!) {
    trackMemberRole(id: $id)
  }
`;

export const GET_APPROVED_SUBMISSIONS = gql`
  query getApprovedSubmissions($eventSlug: String!) {
    approvedSubmissions(eventSlug: $eventSlug) {
      id
        status
        payment_status
        rate
        user_rate
        created_at
        email
        avatar
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_name
            type
            value
          }
        }
        name
        artist_name
        talkSessions{
          title
        }
    }
  }
`;
export const GET_TALK_SESSION_SUBMISSIONS = gql`
  query getTalkSessionSubmissions($eventSlug: String!) {
    talkSessionSubmissions(eventSlug: $eventSlug) {
      id
        status
        payment_status
        rate
        user_rate
        created_at
        email
        avatar
        user {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_name
            type
            value
          }
        }
        name
        artist_name
        talkSession {
          title
        }
    }
  }
`;
export const GET_TALK_SESSIONS = gql`
  query getTalkSessions($eventSlug: String!) {
    talkSessions(eventSlug: $eventSlug) {
        title
        slug
        venue
        start_date
        end_date
        track_id
        type
        sessionChair {
          name,
          email
        }
        talks {
          id
          title,
          start_time,
          end_time,
          abstract
          type
          speakers {
            name,
            email,
          }
        }
    }
  }
`;
export const GET_TALK_SESSION = gql`
  query getTalkSession($slug: String!) {
    talkSession(slug: $slug) {
        id
        title
        slug
        venue
        start_date
        end_date
        type
        track_id
        sessionChair {
          name,
          email
        }
        talks {
          id
          title,
          start_time,
          end_time,
          abstract
          type
          speakers {
            name,
            email,
            pivot {
              speaker_bio
            }
          }
        }
        papers {
          name
          sessionDetails{
            from
            to
            order
            booth
          }
        }
    }
  }
`;
export const GET_PRORGAM_AGENDA = gql`
  query programAgenda($eventSlug: String!) {
    programAgenda(eventSlug: $eventSlug) {
        title
        venue
        start_date
        end_date
        type
        sessionChair {
          name,
          email
        }
        talks {
          id
          title,
          start_time,
          end_time,
          abstract
          type
          speakers {
            name,
            email,
          }
        }
        papers {
          name
          pivot{
            from
            to
            order
            booth
          }
          user {
            id
            name
            email
            avatar {
              name
              path
              placeholder
              thumb
            }
          }
          elements {
            field_id
            field_name
            type
            subtype
            order
            value
            child_elements {
              field_name
              type
              value
            }
          }
        }
        
    }
  }
`;

export const OPPORTUNITY_FEEDBACK_FORM = gql`
  query($opportunity: ID!) {
    opportunityFeedbackForm(opportunity: $opportunity) {
      id
      fields {
        id
        label
        type
        order
        subtype
        is_required
        choices
        child_fields {
					id
          label
        	slug
          type
        }
      }
      opportunity {
        ...opportunityCard
      }
      supported_languages
      is_active
      is_skipped
      max_members
    }
  }
  ${Fragments.opportunityCard}
`;

export const VIEW_APPLICANT_FEEDBACK = gql`
  query($slug: String, $filters: ApplicationFilterInput, $first: Int!, $page: Int) {
    userApplicantFeedback(slug: $slug, filters: $filters, first: $first, page: $page) {
      paginatorInfo {
        ...paginator
      }
      data {
        id
        opportunity {
          id
          slug
        }
        elements {
          field_id
          field_name
          type
          subtype
          order
          value
          child_elements {
            field_id
            field_name
            type
            subtype
            order
            value
          }
        }
      }
    }
  }
  ${Fragments.paginator}
`;