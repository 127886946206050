// eslint-disable-next-line import/no-self-import
import Echo from 'laravel-echo';
import socketio from 'socket.io-client';
import cookie from 'cookie';

let token = ''; // Default token value

if (typeof window !== 'undefined') {
  // Retrieve the token from the cookie
  const cookies = cookie.parse(document.cookie);
  token = cookies.token || '';
}

const echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.NEXT_PUBLIC_SOCKET_SERVER_HOST,
  client: socketio,
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
});

export default echo;
